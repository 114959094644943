import { render, staticRenderFns } from "./DetailSuperLegalStructure.vue?vue&type=template&id=7464982a"
import script from "./DetailSuperLegalStructure.vue?vue&type=script&lang=js"
export * from "./DetailSuperLegalStructure.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7464982a')) {
      api.createRecord('7464982a', component.options)
    } else {
      api.reload('7464982a', component.options)
    }
    module.hot.accept("./DetailSuperLegalStructure.vue?vue&type=template&id=7464982a", function () {
      api.rerender('7464982a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/legalStructures/superLegalStructure/DetailSuperLegalStructure.vue"
export default component.exports